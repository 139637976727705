<template>
  <v-card-text class="package-description">
    <template v-for="bundle of bundlesGroups">
      <v-row :key="`bundle-group-${bundle.id}`">
        <v-col cols="3">
          <v-icon v-if="isChecked(bundle.id)">
            {{ doneIcon }}
          </v-icon>
        </v-col>
        <v-col cols="9">
          <div
            v-for="plugin of bundle.plugins"
            :key="`bundle-group-plugin-${bundle.id}-${plugin.name}`"
          >
            {{ plugin.name }}
          </div>
        </v-col>
      </v-row>
      <v-divider
        :key="`divider-${bundle.id}`"
        class="group-plugin-divider"
      />
    </template>
  </v-card-text>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    bundleGroupIds: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      doneIcon: 'done'
    }
  },
  computed: {
    ...mapState('productDetails', ['formFields', 'availablePlugins']),
    bundlesGroups () {
      return this.availablePlugins ? this.availablePlugins.bundlesGroups : []
    }
  },
  methods: {
    isChecked (bundleId) {
      return this.bundleGroupIds.includes(bundleId)
    }
  }
}
</script>

<style>
  .package-description .col {
    align-self: center;
    display: table;
  }
  .package-description .v-icon {
    margin-left: auto;
    display: table;
  }
  .package-description .row {
    justify-content: center;
  }
  .group-plugin-divider:last-of-type {
    display: none;
  }
</style>
